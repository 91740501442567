<template>
    <div>
        <page-header
            @onClose="$router.go(-1)"
            show-close
        />
        <div class="container">
            <div class="col-10 offset-1 col-md-8 offset-md-2 mt-3">
                <h5
                    class="mb-4 text-center"
                    v-html="$t('pages.unfreezeCard.message')"
                />
                <form-container
                    ref="passwordForm"
                    @onSubmit="unfreezeCard"
                    data-testid="password-form"
                >
                    <form-field
                        ref="passwordInput"
                        v-model="password"
                        name="password"
                        input-type="password"
                        class="mb-2"
                        validation="required"
                        validation-mode="lazy"
                        :placeholder="$t('pages.passwordLogin.placeHolder')"
                        data-testid="password-form-password-input"
                    />
                    <base-button
                        type="submit"
                        :submitting="submitting"
                    >
                        {{ $t('global.cta.continue') }}
                    </base-button>
                </form-container>
            </div>

            <modal
                :show="modalVisible"
                :title="activeModal.title"
                :disable-close="true"
            >
                <p
                    class="mb-3"
                    v-html="activeModal.description"
                />
                <base-button
                    class="mt-3"
                    @click="$router.go(-1)"
                    button-classes="btn btn-primary"
                >
                    {{ $t('pages.unfreezeCard.modal.cta') }}
                </base-button>
            </modal>
        </div>
    </div>
</template>

<script lang="js">
    import {unfreezeCard} from "@/services/api";
    import FormContainer from "@/components/base/FormContainer";
    import modal from "@/components/Modal";
    import BaseButton from "@/components/base/BaseButton";
    import {i18n} from "@/utils/i18n";
    import {logger} from "@/utils/logger";
    import PageHeader from "@/components/PageHeader";
    import FormField from "@/components/base/FormField";

    export default {
        components: {
            FormContainer,
            FormField,
            modal,
            BaseButton,
            PageHeader
        },
        data() {
            return {
                password: "",
                submitting: false,
                avenPassCodeLength: 6,
                successModal: {
                    title: i18n.t('pages.unfreezeCard.modal.unfreezeCard.title'),
                    description: i18n.t('pages.unfreezeCard.modal.unfreezeCard.description'),
                },
                recoverableErrorModal: {
                    title: i18n.t('pages.unfreezeCard.modal.recoverable.title'),
                    description: i18n.t('pages.unfreezeCard.modal.recoverable.description'),
                },
                unrecoverableErrorModal: {
                    title: i18n.t('pages.unfreezeCard.modal.unrecoverable.title'),
                    description: i18n.t('pages.unfreezeCard.modal.unrecoverable.description'),
                },
                activeModal: {
                    type: Object,
                    default: this.unrecoverableErrorModal
                },
                modalVisible: false,
            };
        },
        methods: {
            unfreezeCard: async function() {
                const isValid = await this.$refs.passwordForm.$refs.observer.validate()
                if (!isValid) {
                    return
                }
                this.submitting = true
                try {
                    logger.info(`user initiated unfreezeCard`)
                    this.$refs.passwordForm.clearErrorMessage()
                    const response = await unfreezeCard(this.password)
                    logger.info(`unfreezeCard response: ${JSON.stringify(response.data)}`)
                    if (response.data.success) {
                        this.activeModal = this.successModal
                    } else if(response.data.error === 'CORE_CARD_ERROR') {
                        this.activeModal = this.recoverable
                    } else {
                        this.activeModal = this.unrecoverableErrorModal
                    }
                    this.modalVisible = true
                } catch (error) {
                    this.password = ''
                    logger.info(`unfreezeCard error: ${error.status} ${error.message || ""}`)
                    if (error.response?.status === 401) {
                        this.submitting = false
                        this.$refs.passwordInput.setFocus()
                        this.applyGeneralError(i18n.t("pages.unfreezeCard.error.incorrectPassword"))
                    } else {
                        this.activeModal = this.unrecoverableErrorModal
                        this.modalVisible = true
                    }
                }
            },
            applyGeneralError(errorMessage) {
                this.$refs.passwordForm.applyGeneralError(errorMessage)
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';

    .dd {
        margin-left: 2rem;
    }
</style>
